import './src/styles/global.css'
import 'aos/dist/aos.css'

// Require AOS and Smoothscroll from npm packages
if (typeof window !== 'undefined') {
  // Scroll and AOS for DEV
  require('smooth-scroll')('a[href*="#"]')
  const AOS = require('aos')
  AOS.init()
}

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
